import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { SocialIcon } from "react-social-icons"
import {
  JavascriptIcon,
  TypescriptIcon,
  ReactIcon,
  D3Icon,
  PostgresIcon,
  AWSIcon,
  NodeIcon,
  GatsbyIcon,
  PythonIcon,
  VSCodeIcon,
  GitHubIcon,
  IllustratorIcon,
  PhotoshopIcon,
  AppleIcon,
  LinuxIcon,
} from "../components/Icons"

const StyledLink = styled.a`
  padding-top: 10px;
`

const IconsWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-top: 20px;
  width: 250px;
`
const CentreWrapper = styled.div`
  display: flex;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  padding-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
`

const About = ({ location }) => {
  return (
    <Layout location={location}>
      <h2>
        My name's <b>Adam Robinson.</b>
      </h2>
      <h4>{`I'm a Software Engineer, Designer & Maker.`}</h4>
      <iframe
        title={"Adam Robinson"}
        cursor="pointer"
        id="adam-face-3d"
        width="100%"
        height="380"
        scrolling="no"
        frameborder="no"
        src="https://confident-snyder-a05c9e.netlify.com/"
      ></iframe>
      I have a bit of an obsession for creating immersive 3D web experiences. I
      have always been fascinated by the intersection of technology and
      creativity, and my work reflects my dedication to combining these two
      elements in innovative and exciting ways.
      <br />
      <br />
      My particular interest in 3D web experiences stems from my belief that the
      future of the internet lies in creating fully immersive environments that
      users can interact with in new and exciting ways. I am constantly
      exploring new technologies and tools to help bring this vision to life,
      and I am excited to be a part of a community of like-minded individuals
      who share my passion.{" "}
      <StyledLink href="https://adamrobinson.dev">
        [Check out my 3D Portfolio]
      </StyledLink>
      {/* <StyledLink
        download
        href="https://attackingpixels.com/adam-robinson-curriculum-vitae.pdf"
      >
        CV
      </StyledLink> */}
      <CentreWrapper>
        <IconsWrapper>
          <SocialIcon
            url="http://linkedin.com/in/adamgrobinson"
            style={{ height: 32, width: 32 }}
            bgColor={"var(--logo)"}
          />
          <SocialIcon
            style={{ height: 32, width: 32 }}
            url="http://facebook.com/adamistheanswer"
            bgColor={"var(--logo)"}
          />
          <SocialIcon
            style={{ height: 32, width: 32 }}
            url="http://instagram.com/adamistheanswer"
            bgColor={"var(--logo)"}
          />
          <SocialIcon
            style={{ height: 32, width: 32 }}
            url="http://github.com/adamistheanswer"
            bgColor={"var(--logo)"}
          />
        </IconsWrapper>
      </CentreWrapper>
      <h4> What I've been up to...</h4>
      <br />
      <ul>
        <li>
          (May 2022 - Present) Lead Software Engineer (Space & Range){" "}
          <span role="img" aria-label="Job">
            👔{" "}
          </span>
          <a href="https://www.hollandandbarrett.com/">
            @Holland {"&"} Barrett
          </a>
          , Remote
        </li>
        <li>
          (Jul 2020 - May 2022) Software Engineer SDE2 (Supply Chain){" "}
          <span role="img" aria-label="Job">
            👔{" "}
          </span>
          <a href="https://www.hollandandbarrett.com/">
            @Holland {"&"} Barrett
          </a>
          , Remote
        </li>
        <li>
          (Dec 2018 - Jun 2020) Full-Stack Engineer{" "}
          <span role="img" aria-label="Job">
            👔{" "}
          </span>
          <a href="https://defty.com/">@defty</a>, London,{" "}
          <span role="img" aria-label="Masked Face">
            😷 [Closed - Covid-19]
          </span>
        </li>
        <li>
          (Class 2017)
          <b>
            {" "}
            M.Sc Computer Science [Distinction]{" "}
            <span role="img" aria-label="Uni">
              🎓{" "}
            </span>
          </b>
          <a href="https://www.birmingham.ac.uk/postgraduate/courses/taught/computer-science/computer-science.aspx">
            The University of Birmingham
          </a>
        </li>
        <li>
          (Oct 2015 - Jun 2016) Business Accelerator{" "}
          <span role="img" aria-label="Biz">
            🔥{" "}
          </span>{" "}
          <a href="https://www.birmingham.ac.uk/postgraduate/courses/taught/computer-science/computer-science.aspx">
            @Entrepreneurial Spark
          </a>
          , Leeds{" "}
        </li>{" "}
        <li>
          (Mar 2014 - Jan 2016) Lead Product Designer{" "}
          <a href="https://www.attackingpixels.com/SLPY-Wearable-Sleeping-Bag/">
            <span role="img" aria-label="Job">
              👔{" "}
            </span>{" "}
            @SLPY
          </a>
          , Remote{" "}
        </li>{" "}
        <li>
          (Jan 2014 - Jan 2017) Co-Founder Designer{" "}
          <a href="https://www.attackingpixels.com/Attacking-Design/">
            <span role="img" aria-label="Job">
              👔{" "}
            </span>{" "}
            @Attacking Design
          </a>
          , Leeds{" "}
        </li>
        <li>
          {" "}
          (Jul 2013 - Dec 2013) Junior Product Designer{" "}
          <a href="http://www.disruptive-innovation.co.uk/">
            <span role="img" aria-label="Job">
              👔{" "}
            </span>{" "}
            @Disruptive Innovation
          </a>
          , Leeds{" "}
        </li>{" "}
        <li>
          (Class 2010) <b>B.Des Product Design [First Class, Hons]</b>{" "}
          <span role="img" aria-label="Uni">
            🎓{" "}
          </span>{" "}
          <a href="https://eps.leeds.ac.uk/product-design">
            The University of Leeds
          </a>
        </li>
      </ul>
      <h4>Technologies I like:</h4>
      <IconWrapper>
        <JavascriptIcon />
        <TypescriptIcon />
        <ReactIcon />
        <D3Icon />
        <PostgresIcon />
        <AWSIcon />
        <NodeIcon />
        <GatsbyIcon />
        <PythonIcon />
      </IconWrapper>
      <h4>Tools I use:</h4>
      <IconWrapper>
        <VSCodeIcon />
        <GitHubIcon />
        <IllustratorIcon />
        <PhotoshopIcon />
        <AppleIcon />
        <LinuxIcon />
      </IconWrapper>
      <br />
      <hr />
      <iframe
        title={"Soundcloud"}
        width="100%"
        height="166"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/156893117&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      ></iframe>
      <p>
        <strong>
          <span role="img" aria-label="goal">
            🥅{" "}
          </span>
          2023 Goals:
        </strong>{" "}
        Keep on top of AI 3D asset generation & WebGPU Release
        <br />
        <br />
        <strong>
          <span role="img" aria-label="goal">
            🥅{" "}
          </span>
          2022 Goals:
        </strong>{" "}
        WebXR & VR
        <br />
        <br />
        <strong>
          <span role="img" aria-label="goal">
            🥅{" "}
          </span>
          2021 Goals:
        </strong>{" "}
        Land a job utilising 3D in the browser ✅
        <br />
        <br />
        <strong>
          <span role="img" aria-label="goal">
            🥅{" "}
          </span>
          2020 Goals:
        </strong>{" "}
        Learn Solidarity (ETH),{" "}
        <strike>
          visit Vietnam{" "}
          <span role="img" aria-label="Masked Face">
            😷
          </span>
        </strike>{" "}
        and <strike>work more with 3D </strike> <br />
        <br />
        <strong>
          <span role="img" aria-label="goal">
            🥅{" "}
          </span>
          2019 Goals:
        </strong>{" "}
        Fastpack Offas Dyke, <strike>visit Albania </strike> and{" "}
        <strike>write more code </strike>
      </p>
      <br />
      <br />
    </Layout>
  )
}

export default About
